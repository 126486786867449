@import url('https://rsms.me/inter/inter.css');
@import url('https://unpkg.com/modern-normalize@0.6.0/modern-normalize.css');

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: var(--rem);
  line-height: 1.5;
}

body {
  color: var(--colorTextDefault);
  font-size: 0.875rem;
  font-family: var(--fontFamilySans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* Image resets */

img {
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

/* Typography resets */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li {
  margin-top: 0;
}

h1 {
  font-size: 1.6875rem;
  line-height: 1.4;
}

h2 {
  font-size: 1.625rem;
  line-height: 1.4;
}

h3 {
  font-size: 1.5rem;
  line-height: 2;
}

h1,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: 600;
}

small {
  font-size: 0.75rem;
  line-height: 1rem;
}

a {
  color: var(--colorAnchorText);
  transition: color 200ms ease;
  text-decoration: none;
}

a:hover {
  color: var(--colorAnchorTextHover);
}

code,
pre {
  font-family: var(--fontFamilyMono);
}

/* General use type classes */

/* Typography for marketing pages */
.headline,
.subhead,
.headline-secondary,
.subhead-secondary {
  margin: 0;
  font-family: var(--fontFamilySerif);
  font-weight: 500;
  line-height: 1.2;
}

.headline,
.subhead {
  font-size: 3rem; /* 48pt */
  letter-spacing: -0.025em;
}

@media (min-width: 768px) {
  .headline,
  .subhead {
    letter-spacing: -0.03em;
  }
}

.subhead {
  margin-bottom: 4rem;
  color: var(--colorTextMuted);
}

.headline-secondary,
.subhead-secondary {
  font-size: 2.625rem; /* 42pt */
  letter-spacing: -0.025em;
}

.subhead-secondary {
  margin-bottom: 4rem;
  color: var(--colorTextMuted);
}

.linklist {
  list-style: none;
  padding-left: 0;
}

/* Typographic Styles */
.typography-caption {
  margin-bottom: 1rem;
  color: var(--colorTextSemiMuted);
  font-family: var(--fontFamilySans);
  text-align: inherit;
}

.typography-nano {
  display: inline;
  text-align: inherit;
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

/* Typography utils */
.colorTextDefault {
  color: var(--colorTextDefault);
}

.colorTextMuted {
  color: var(--colorTextMuted);
}

/* Responsive utils */
@media (max-width: 767px) {
  .hideForPhone {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .showForPhoneOnly {
    display: none !important;
  }
}

/* Swiper */
.swiper-pagination {
  bottom: 2rem !important;
}
.swiper-pagination-bullet {
  color: var(--colorTextVeryMuted);
  height: 4px !important;
  width: 4px !important;
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.swiper-pagination-bullet-active {
  background: black !important;
}
.pagination-modifier {
  left: 50%;
  transform: translate(-50%, -50%) !important;
  bottom: 0px !important;
}

.typeography_heading__xIyyV {
  font-family: var(--fontFamilySans);
  font-weight: 500;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}

.typeography_subheading__5aoAf {
  font-family: var(--fontFamilySans);
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}

.typeography_bodyText__7C7Ye {
  font-family: var(--fontFamilySans);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.typeography_smallText__jhfdP {
  font-family: var(--fontFamilySans);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

/***/
/* NEW TYPOGRAPHY CLASSES: */

.typeography_brand48__JDrwt {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.015em;
}
.typeography_brand40__xmwya {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.015em;
}
.typeography_brand32__JCgUy {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.015em;
}
.typeography_brand28__j4mhI {
  font-family: var(--fontFamilySerif);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.015em;
}

.typeography_ui32SemiBold__PzQiM {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.96px;
}

.typeography_ui28Bold___Dn4h {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
.typeography_ui28SemiBold__rl85W {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
.typeography_ui28Medium__Z49UY {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.03em;
}

.typeography_ui20Bold__SUgYJ {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.typeography_ui20SemiBold__BulCK {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}
.typeography_ui20Medium__idy4H {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.015em;
}

.typeography_ui16Bold__zmoXC {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16SemiBold__4AoKd {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16Medium__cbuWD {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}
.typeography_ui16Regular__0dRCl {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.0125em;
}

.typeography_ui14Bold__ESeJ5 {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}
.typeography_ui14SemiBold__T0jgm {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
}
.typeography_ui14Medium__LuJ8w {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
}
.typeography_ui14Regular__RU8O4 {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
}

.typeography_ui12SemiBold__3xcry {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}
.typeography_ui12Medium__Jda7a {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}
.typeography_ui12Regular__R0d9s {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.004em;
}

.typeography_ui11SemiBold__QKYkF {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui11Medium__eulDk {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui11Regular__dSzmA {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.004em;
}
.typeography_ui10Regular__DxFxW {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.04px;
}

.Toast_toast__8tgM1 {
  --toastInfoColor: var(--colorGray8);
  --toastInfoBackgroundColor: white;
  --toastInfoIconColor: var(--colorBlue8);
  --toastInfoBarColor: var(--colorBlue8);

  --toastErrorColor: var(--colorGray8);
  --toastErrorBackgroundColor: white;
  --toastErrorIconColor: var(--colorRed8);
  --toastErrorBarColor: var(--colorRed8);

  --toastSuccessColor: var(--colorGray8);
  --toastSuccessBackgroundColor: white;
  --toastSuccessIconColor: var(--colorGreen8);
  --toastSuccessBarColor: var(--colorGreen8);

  --toastWarningColor: var(--colorGray8);
  --toastWarningBackgroundColor: white;
  --toastWarningIconColor: var(--colorYellow8);
  --toastWarningBarColor: var(--colorYellow7);
}

.Toast_info__eA_RT,
.Toast_warning__ltuuH,
.Toast_error__J24pO,
.Toast_success__7nbc0 {
}

.Toast_toastTitleInfo__3H1GP,
.Toast_toastTitleWarning__bcfgv,
.Toast_toastTitleSuccess__RfC8T,
.Toast_toastTitleError__odPlL {
}

.Toast_toast__8tgM1 {
  padding: 12px 16px;
  color: var(--toastInfoColor);
  background-color: var(--toastInfoBackgroundColor);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.18);
  max-width: 320px;
  border-left: 6px solid var(--toastInfoBarColor);
}

.Toast_toastInner__gYuEF {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.Toast_toastIcon__ByQ0O {
  flex-shrink: 0;
  margin-top: 2px;
  color: var(--toastInfoIconColor);
  margin-right: 1rem;
}

.Toast_toastRight__pS3mx {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Toast_condensed__Sfn6i .Toast_toastRight__pS3mx {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Toast_toastTitle__Lhmq5 {
  color: var(--colorBlack2);
}

.Toast_condensed__Sfn6i .Toast_toastTitle__Lhmq5 {
  margin-right: 12px;
}

.Toast_dismissable__bLQOZ .Toast_toastMessage__H9U4X {
  max-width: 235px;
}

.Toast_toastMessage__H9U4X {
  text-align: left;
}

.Toast_toastMessage__H9U4X h4,
.Toast_toastMessage__H9U4X p {
  margin-bottom: 0.5rem;
}

.Toast_toastMessage__H9U4X small {
  display: block;
  line-height: inherit;
  margin-bottom: 0.5rem;
}

.Toast_toast__8tgM1.Toast_error__J24pO {
  color: var(--toastErrorColor);
  background-color: var(--toastErrorBackgroundColor);
  border-left: 6px solid var(--toastErrorBarColor);
}

.Toast_toast__8tgM1.Toast_error__J24pO .Toast_toastIcon__ByQ0O {
  color: var(--toastErrorIconColor);
}

.Toast_toast__8tgM1.Toast_success__7nbc0 {
  color: var(--toastSuccessColor);
  background-color: var(--toastSuccessBackgroundColor);
  border-left: 6px solid var(--toastSuccessBarColor);
}

.Toast_toast__8tgM1.Toast_success__7nbc0 .Toast_toastIcon__ByQ0O {
  color: var(--toastSuccessIconColor);
}

.Toast_toast__8tgM1.Toast_warning__ltuuH {
  color: var(--toastWarningColor);
  background-color: var(--toastWarningBackgroundColor);
  border-left: 6px solid var(--toastWarningBarColor);
}

.Toast_toast__8tgM1.Toast_warning__ltuuH .Toast_toastIcon__ByQ0O {
  color: var(--toastWarningIconColor);
}

.Toast_dismiss__xlqOg {
  cursor: pointer;
  width: 10px;
}

.ToastContext_globalToastWrapper__MW0D_ {
  position: fixed;
  display: flex;
  justify-content: right;
  z-index: 100;
  top: calc(64px + 32px);
  right: 32px;
}

/* Custom media queries
 * https://github.com/postcss/postcss-custom-media
 * Enabled via custom nextjs postcss config in package.json#postcss
 * https://nextjs.org/docs/advanced-features/customizing-postcss-config#customizing-plugins
 */

/*

SOME NOTES:

We have had 3 different break point systems in use before:

- Original Rails app used Bootstrap 3:

    - 0-767 mobile
    - 768-991 tablet
    - 992-1199 small desktop
    - 1200+ large desktop

    Typically 15 or 30 px is used as padding/margin between elements


- Original Marketplace used:

    - 0-520 mobile
    - 521-740 tablet
    - 741-1024 small desktop
    - 1025+ large desktop

    Typically 16px is used as padding/margin between elements


- New Marketplace/SellerHub breakpoints:

    - 0-767 mobile
    - 768-1023 tablet portrait
    - 1024-1151 tablet landscape
    - 1152-1439 small desktop
    - 1440+ large desktop

    Typically 16px is used as padding/margin between elements


- Seller Hub is typically a max-width 1136 container centered in the page. No need to design for multiple breakpoints

    0-767:     100% max-width container
    768-1023:   768 max-width container
    1024-1151: 1024 max-width container
    1152-1439: 1136 max-width container
    1440+:     1136 max-width container

- Marketplace uses the following max-width containers at each breakpoint

    0-767:     100% max-width container
    768-1023:   640 max-width container
    1024-1151:  864 max-width container
    1152-1439: 1088 max-width container
    1440+:     1312 max-width container


*/

:root {
  --rem: 16px;
  --scrollPosition: 0px;

  /* Base Colors */
  --colorBlue: hsla(218, 100%, 58%, 1);
  --colorBlueDark: hsla(217, 72%, 48%, 1);
  --colorBlueDark2: hsla(218, 72%, 37%, 1);
  --colorBlueFade: rgba(34, 100, 209, 0.1);
  --colorBlue1: hsla(222, 100%, 98%, 1);
  --colorBlue2: hsla(219, 100%, 96%, 1);
  --colorBlue3: hsla(218, 100%, 92%, 1);
  --colorBlue4: #c4daff;
  --colorBlue5: hsla(217, 100%, 81%, 1);
  --colorBlue6: hsla(218, 100%, 73%, 1);
  --colorBlue7: #4f91ff;
  --colorBlue8: hsla(218, 100%, 58%, 1);
  --colorBlue9: hsla(217, 72%, 48%, 1);
  --colorBlue10: hsla(218, 72%, 37%, 1);
  --colorBlue11: #133774;
  --colorBlue12: #0c2146;

  --colorGreen: hsl(83, 94%, 42%);
  --colorGreenDark: hsl(83, 96%, 28%);
  --colorGreenDark2: hsl(172, 7%, 24%);
  --colorGreenDark3: hsl(150, 2%, 13%);
  --colorGreenFade: hsla(83, 90%, 42%, 16%);

  --colorGreen1: hsla(120, 33%, 97%, 1);
  --colorGreen2: #ebf7ed;
  --colorGreen3: hsla(124, 32%, 90%, 1);
  --colorGreen4: #cbe5cc;
  --colorGreen5: #a9d3ab;
  --colorGreen6: hsla(122, 33%, 65%, 1);
  --colorGreen7: hsla(122, 33%, 55%, 1);
  --colorGreen8: hsla(123, 41%, 45%, 1);
  --colorGreen9: hsla(123, 41%, 36%, 1);
  --colorGreen10: hsla(123, 41%, 28%, 1);
  --colorGreen11: #1f4921;
  --colorGreen12: #132c14;

  --colorPurple: hsl(293, 78%, 49%);
  --colorPurple1: #faf5fe;
  --colorPurple2: #f3ecfd;
  --colorPurple3: #e8d9fc;
  --colorPurple4: #dcc6fb;
  --colorPurple5: #c4a1f9;
  --colorPurple6: #ae7cf7;
  --colorPurple7: #9859f6;
  --colorPurple8: #843af5;
  --colorPurple9: #6c30c9;
  --colorPurple10: #54259d;
  --colorPurple11: #3d1a70;
  --colorPurple12: #250f43;
  --colorPurpleDark: hsl(293, 86%, 38%);
  --colorPurpleFade: hsla(293, 69%, 68%, 19%);

  --colorRed: hsl(2, 76%, 51%);
  --colorRed1: #fef2f1;
  --colorRed2: #fee8e7;
  --colorRed3: #fddcda;
  --colorRed4: hsla(3, 90%, 89%, 1);
  --colorRed5: #faa9a3;
  --colorRed6: hsla(4, 90%, 74%, 1);
  --colorRed7: #f6655a;
  --colorRed8: hsla(4, 90%, 58%, 1);
  --colorRed9: hsla(4, 63%, 48%, 1);
  --colorRed10: hsla(4, 63%, 37%, 1);
  --colorRed11: #6f1f19;
  --colorRed12: #43130f;
  --colorRedDark: hsl(2, 82%, 44%);
  --colorRedFade: rgba(200, 55, 45, 0.08);

  --colorOrange: #f49135;

  --colorYellow: hsl(42, 95%, 55%);
  --colorYellow1: #fff8eb;
  --colorYellow2: #ffefd1;
  --colorYellow3: #ffe5b3;
  --colorYellow4: #ffd98f;
  --colorYellow5: #f5ce84;
  --colorYellow6: #ebbf67;
  --colorYellow7: #e5ae40;
  --colorYellow8: #d6981b;
  --colorYellow9: #b88217;
  --colorYellow10: #8f6512;
  --colorYellow11: #66480d;
  --colorYellow12: #463209;
  --colorYellowDark: hsl(42, 95%, 39%);
  --colorYellowFade: hsla(42, 95%, 55%, 15%);

  --colorWhite: white;

  --colorBlack: hsl(0, 0%, 13%);
  --colorBlack2: hsl(0, 0%, 0%);
  --colorBlack3: hsl(173, 7%, 24%);
  --colorBlackDark: hsl(0, 0%, 3%);
  --colorBlackFade: rgba(0, 0, 0, 0.08);

  --colorGray: hsl(207, 5%, 84%);
  --colorGray0: #fafafa;
  --colorGray1: #f7f7f7;
  --colorGray2: #efefef;
  --colorGray3: #e2e2e2;
  --colorGray4: #d4d4d4;
  --colorGray5: #b7b7b7;
  --colorGray6: #9b9b9b;
  --colorGray7: #7f7f7f;
  --colorGray8: #626262;
  --colorGray9: #515151;
  --colorGray10: #3f3f3f;
  --colorGray11: #2d2d2d;
  --colorGray12: #1b1b1b;
  --colorDarkGray: hsl(0, 0%, 25%);

  --colorTan1: #fbfaf9;
  --colorTan2: #f4f3f1;
  --colorTan3: #edece8;
  --colorTan4: #e4e2dd;
  --colorTan5: #d2cfc6;
  --colorTan6: #c0bbaf;
  --colorTan7: #aea898;
  --colorTan8: #9f9784;
  --colorTan9: #837b67;
  --colorTan10: #676151;
  --colorTan11: #4a463a;
  --colorTan12: #2e2b24;

  --colorSuna: hsl(42, 43%, 49%);
  --colorGold: #b39b47;

  /* These are old tan colors, not really consistent with new tan colors. Don't use unless you need to... */
  --colorOldTan1: #fbfbfa;
  --colorOldTan2: #f7f5f2;
  --colorOldTan3: #837d6a;
  --colorOldTan4: #edece8;

  --colorSpotlightBackground: hsla(36, 24%, 96%, 1);

  /* Brand Color */
  --colorPrimary: var(--colorBlue);
  --colorPrimaryDark: var(--colorBlueDark);
  --colorPrimaryDark2: var(--colorBlueDark2);
  --colorPrimaryFade: var(--colorBlueFade);

  /* Element Color */
  --colorAnchorText: var(--colorPrimary);
  --colorAnchorTextHover: var(--colorPrimaryDark);

  --colorButtonDefault: var(--colorGreenDark2);

  --colorBadgeDefaultText: var(--colorTextDefault);
  --colorBadgeDefaultBackground: hsl(0, 0%, 92%);

  --colorBorderDefault: var(--colorGray3);
  --colorBorderMuted: hsl(0, 0%, 90%);
  --colorBorderVeryMuted: hsl(0, 0%, 92%);

  --colorDropdownItemHoverBackground: rgba(0, 0, 0, 0.05);
  --colorDropdownItemSelectedBackground: hsl(0, 0%, 97%);

  --colorFieldTitle: var(--colorGray8);
  --colorFieldDisabled: var(--colorGray9);

  --colorFooterForeground: hsl(250, 5%, 60%);
  --colorFooterBackground: hsl(250, 14%, 12%);

  --colorHelpIconDefault: hsl(0, 0%, 85%);
  --colorHelpIconHover: var(--colorPrimary);

  --colorProductSidebarItemSelectedBackground: hsl(0, 0%, 97%);
  --colorProductSidebarItemHoverBackground: hsl(0, 0%, 98%);

  --colorPanelBackgroundGrey: hsl(0, 0%, 98%);
  --colorPanelBackgroundWarm: hsl(40, 38%, 98%);
  --colorPanelBackgroundBrown: hsla(36, 24%, 96%, 1);

  --colorImagePlaceholderIcon: hsl(0, 0%, 90%);

  --colorInputFocusBorder: var(--colorBlue7);
  --colorInputFocusOutline: var(--colorPrimaryFade);
  --colorInputPlaceholder: var(--colorGray6);
  --colorInputDisabled: var(--colorGray9);

  --colorModalBodyBackground: hsl(0, 0%, 98%);

  --colorPageBackgroundDefault: white;
  --colorPageBackgroundLightGrey: hsl(0, 0%, 98%);

  --colorProgressTrack: hsl(0, 0%, 0%, 0.04);
  --colorProgressBar: var(--colorGreen6);

  --colorProgressSpinnerHighlight: hsl(0, 0%, 20%);

  --colorTextDefault: var(--colorBlack2);
  --colorTextMuted: hsl(0, 0%, 60%);
  --colorTextSemiMuted: hsl(0, 0%, 43%);
  --colorTextVeryMuted: hsl(0, 0%, 77%);

  /* Typography */
  --fontFamilySerif: 'Canela Deck Web', georgia, serif;
  --fontFamilySans: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  --fontFamilyMono: 'SF Mono', Menlo, Courier, monospace;
  --priceLabelFontFeatures: 'tnum', 'ss01';

  /* Arbitrary layout sizes */
  --breadcrumbsHeight: 2.5rem;
  --catalogCoverWidth: 13.5rem;
  --elementBorderRadius: 0.125rem;
  --headerNavHeight: 5rem;
  --previewBannerHeight: 42px;
  --headerNavHeightTotal: var(
    --headerNavHeight
  ); /* this is the total height, inclusive of preview banner (if applicable) */
  --alertBannerHeight: 52px;
  --departmentNavHeight: 4rem;
  --approxCatalogHeaderWrapperHeight: 300px;
  --catalogHeaderWrapperHeight: 11.75rem;
  --tablePadding: 0.75rem;

  /* Catalog Layout */
  --catalogGridItemMinWidth: 11rem;
  --catalogGridGap: 2rem;
  --catalogGridMaxWidth: calc(5 * var(--catalogGridItemMinWidth) + 6 * var(--catalogGridGap));

  /* Seller Hub */

  --sh_colorGrayBorder: hsla(240, 3%, 89%, 1);

  --sh_colorBlack: hsl(251, 14%, 9%);
  --sh_colorGrayLight: hsl(116, 0%, 95%);
  --sh_colorGrayDark: hsl(251, 15%, 16%);
  --sh_containerLg: 1136px;
  --sh_borderRadius: 4px;

  --sh_colorGreen5: hsla(127, 44%, 95%, 1);
  --sh_colorGreen7: hsla(122, 33%, 55%, 1);
  --sh_colorGreen60: hsla(123, 41%, 45%, 1);

  --sh_colorRed0: hsla(3, 86%, 97%, 1);
  --sh_colorRed60: hsla(4, 90%, 58%, 1);

  --sh_colorOrange5: hsla(31, 100%, 94%, 1);
  --sh_colorOrange60: hsla(31, 100%, 49%, 1);

  --sh_colorYellow5: hsla(39, 100%, 91%, 1);
  --sh_colorYellow60: hsla(40, 78%, 47%, 1);

  --sh_colorBlue5: hsla(219, 100%, 96%, 1);
  --sh_colorBlue6: hsla(218, 100%, 73%, 1);
  --sh_colorBlue9: hsla(217, 72%, 48%, 1);
  --sh_colorBlue60: hsla(218, 100%, 58%, 1);

  --sh_colorGray1: hsla(0, 0%, 97%, 1);
  --sh_colorGray2: hsla(0, 0%, 94%, 1);
  --sh_colorGray5: hsla(240, 9%, 94%, 1);
  --sh_colorGray4: hsla(0, 0%, 83%, 1);
  --sh_colorGray6: hsla(240, 5%, 50%, 1);
  --sh_colorGray7: hsla(0, 0%, 50%, 1);
  --sh_colorGray8: hsla(0, 0%, 38%, 1);
  --sh_colorGray10: hsla(0, 0%, 25%, 1);
  --sh_colorGray30: hsla(240, 5%, 72%, 1);
  --sh_colorGray40: hsla(240, 5%, 61%, 1);
  --sh_colorGray60: hsla(236, 8%, 38%, 1);
}

:root {
  /* Colors */
  --colorPanelBackgroundBrown: hsla(36, 24%, 96%, 1);
  --colorPageBackgroundDefault: white;

  /* Typography */
  --fontFamilySerif: 'Canela Deck Web', georgia, serif;
  --fontFamilySans: 'Inter', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;

  /* Arbitrary layout sizes */
  --elementBorderRadius: 0.25rem;
}

/* react-datepicker is given the custom class name 'datePickerOverrides'
 * in /components/DatePicker.tsx
 *
 * Not ideal to have the style overriddes included globally,
 * but react-datepicker is pretty lame when it comes to customising css.
 */

/* The 'wrapper' is the input container */
.datePickerWrapper {
  display: block;
  width: 100%;
}

.datePickerWrapper .react-datepicker__close-icon {
  top: 50%;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.75rem;
}

.datePickerWrapper .react-datepicker__close-icon:after {
  display: block;
  content: '×';
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  line-height: 1.25rem; /* This is essentially a baseline shift of 0.25rem */
  font-size: 1.5rem;
  color: var(--colorTextVeryMuted);
  background-color: transparent;
}

/* The 'popper' is the datepicker container */

.datePickerPopper .react-datepicker {
  font-family: var(--fontFamilySans);
  border: 0 none;
  box-shadow: 0 1rem 4.5rem rgba(0, 0, 0, 0.35);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__header {
  background-color: transparent;
  border-bottom: 1px solid #eaeaea;
}

.datePickerPopper .react-datepicker__input-container {
  display: block;
}

.datePickerPopper .react-datepicker__day-name {
  color: var(--colorTextMuted);
}

.datePickerPopper .react-datepicker__day--selected,
.datePickerPopper .react-datepicker__day--selected:hover,
.datePickerPopper .react-datepicker__day--keyboard-selected,
.datePickerPopper .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--colorBlue);
  border-radius: 2px;
}

.datePickerPopper .react-datepicker__day--outside-month {
  color: var(--colorTextVeryMuted);
}

.datePickerPopper .react-datepicker__triangle {
  display: none;
}

